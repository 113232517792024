import { Spin } from 'antd';
import React from 'react';
import './FullLoader.scss';

interface FullLoaderProps {
  visible: boolean;
}

function FullLoader({ visible }:FullLoaderProps) {
  if (visible) {
    return (
      <section className="full-loader-section">
        <Spin className="spin" spinning />
      </section>
    );
  }
  return null;
}

export default FullLoader;