import * as authService from '../services/auth.service';
import { setItem } from '../utils/storage';

export const login = async (username: string, password: string) => {
  const request = {
    user: username,
    password: password,
  };
  try {
    const response = await authService.login(request);
    const user = response.data.user;
    setItem('user', user);
    return user;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const logout = async () => {
  setItem('user', null);
  setItem('token', null);
};