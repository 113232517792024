import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import * as routes from './constants/routes';
import AuthRequired from './Components/AuthRequired/AuthRequired';
import Login from './Pages/Login/Login';
import Main from './Pages/Main/Main';

const Home = React.lazy(() => import('./Pages/Home/Home'));
const Access = React.lazy(() => import('./Pages/Access/Access'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.MAIN} element={<Main />} />
        <Route path={routes.LOGIN} element={<Login />} />
        <Route element={<AuthRequired />}>
          <Route path={routes.HOME} element={<React.Suspense><Home /></React.Suspense>} />
          <Route path={routes.ACCESS} element={<React.Suspense><Access /></React.Suspense>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
