import React, { useState } from 'react';
import './WelcomeModal.scss';
import { Button, Modal } from 'antd';

interface WelcomeModalProps {
  visible: boolean;
  onDismiss: () => void;
}

function WelcomeModal({ visible, onDismiss }:WelcomeModalProps) {
  const onCloseModal = () => {
    onDismiss();
  };
  return (
    <Modal
      className="welcome-modal"
      open={visible}
      footer={null}
      centered
      onCancel={onCloseModal}
      closable={false}
      width="fit-content"
    >
      <Welcome onEnter={onCloseModal} />
    </Modal>
  );
}

interface Props {
  onEnter: () => void,
}

function Welcome({ onEnter }: Props) {
  return (
    <div className="welcome-container">
      <span>Bienvenido</span>
      <span>a la invitación de boda</span>
      <h3>Gaby y Oscar</h3>
      <Button className="enter-button" onClick={onEnter} type="text">Ingresar</Button>
    </div>
  );
}

export default WelcomeModal;
