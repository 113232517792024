import React from 'react';
import Suit from '../../../assets/img/traje.webp';
import Dress from '../../../assets/img/vestido.webp';
import './DressCode.scss';

function DressCode() {
  return (
    <section className="dess-code-section">
      <div className="main-container">
        <h2>Código de Vestimenta</h2>
        <hr />
        <h3>Formal</h3>
        <div className="side-to-side">
          <div className="dress-container">
            <div className="image-container">
              <img className="suit" src={Suit} alt="traje" />
            </div>
            <span>Traje y corbata</span>
            <p>Caballeros de honor: Reservado el color AZUL MARINO.</p>
          </div>
          <div className="dress-container">            
            <div className="image-container">
              <img className="suit" src={Dress} alt="vestido" />
            </div>
            <span>Vestido</span>
            <p>Reservamos el BLANCO  para la novia. DAMAS DE HONOR: Reservado el color VINO.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DressCode;
