import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { LOGIN } from '../constants/routes';
import { SAVE_GUESTS } from '../constants/servicesRoutes';
import { getItem } from '../utils/storage';
import { refreshToken } from '../utils/refreshToken';

export const authResponseSuccess = (response: AxiosResponse) => {
  try {
    const { data } = response;
    if (data?.token) {
      refreshToken(data!.token);
    }
    const { data: { entity } } = response;
    const code = response.status;
    if(code !== 200) {
      throw entity;
    }
  } catch (error) {
    throw response.data;
  }
  return response;
};

export const authResponseFailed = (response: AxiosResponse) => {
  console.error('Response failed', response);
  throw response;
};

export const authRequest = (config: InternalAxiosRequestConfig<any>) => {
  const newConfig = config;
  if (!config.url!.includes(LOGIN) && !config.url!.includes(SAVE_GUESTS)) {
    const token = getItem('token');
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
};
