import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './Sound.scss';
import { Button } from 'antd';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';

interface SoundParams {
  isPlaying: boolean;
  onPlayClick: () => void;
}

function Sound({ isPlaying, onPlayClick }:SoundParams) {

  return (
    <div className="sound-container">
      <Button className="sound-button" onClick={onPlayClick}>
        {isPlaying ? (
          <PauseOutlined className="icon" />
        ) : (
          <CaretRightOutlined className="icon" />
        )}
      </Button>
    </div>
  );
}

export default Sound;
