import React, { useEffect, useState } from 'react';
import './Countdown.scss';

const INITIAL_COUNTDOWN = {
  days:0,
  hours:0,
  minutes:0,
  seconds:0,
};
const TARGET_DATE_STRING = '2023-11-04T16:00:00.000Z';

let timer: ReturnType<typeof setTimeout>;

interface CDTime {
  days:number,
  hours:number,
  minutes:number,
  seconds:number,
}

function Countdown() {
  const [countdown, setCountdown] = useState<CDTime>(INITIAL_COUNTDOWN);
  const onTick = () => {
    const current = (new Date()).valueOf();
    const target = (new Date(TARGET_DATE_STRING)).valueOf();
    const countdownS = (target - current) / 1000 + 21600;
    const days = Math.floor(countdownS / 86400);
    const hours = Math.floor((countdownS - (days * 86400)) / 3600);
    const minutes = Math.floor((countdownS - (days * 86400) - (hours * 3600)) / 60);
    const seconds = Math.floor(countdownS - (days * 86400) - (hours * 3600) - minutes * 60);
    setCountdown({ days, hours: hours, minutes, seconds });
  };
  useEffect(() => {
      timer = setInterval(onTick, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  
  return (
    <section className="countdown-section">
      <div className="container">
        <h2 className="title">Gaby & Oscar</h2>
        <h3 className="subtitle">Nuestra Boda</h3>
        <div className="count-wrapper">
          <h4 className="remain-label">¡Solo faltan!</h4>
          <div className="countdown-container">
            <div className="count-column">
              <span className="count">{`${countdown!.days < 10 ? '0' : ''}${countdown?.days}`}</span>
              <span className="label">Días</span>
            </div>
            <div className="count-column">
              <span className="count">{`${countdown!.hours < 10 ? '0' : ''}${countdown?.hours}`}</span>
              <span className="label">Horas</span>
            </div>
            <div className="count-column">
              <span className="count">{`${countdown!.minutes < 10 ? '0' : ''}${countdown?.minutes}`}</span>
              <span className="label">Minutos</span>
            </div>
            <div className="count-column">
              <span className="count">{`${countdown!.seconds < 10 ? '0' : ''}${countdown?.seconds}`}</span>
              <span className="label">Segundos</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Countdown;
