export const getItem = (key:string) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value) return JSON.parse(value);
    return null;
  } catch (error) {
    return null;
  }
};

export const setItem = (key:string, item:any) => {
  sessionStorage.setItem(key, JSON.stringify(item));
};