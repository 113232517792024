import React, { useState } from 'react';
import { Button, Form, Input, Radio, RadioChangeEvent } from 'antd';
import './Attendance.scss';
import { saveGuests } from '../../../actions/guests.actions';
import { DeleteOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

function Attendance() {
  return (
    <section className="attendance-section">
      <div className="side-to-side-container">
        <div className="message-container">
          <h2>Confirmar Asistencia</h2>
          <hr className="line" />
          <p>Es muy importante para nosotros contar con tu confirmación lo antes posible.</p>
        </div>
        <AttendanceForm />
      </div>
    </section>
  );
}

function AttendanceForm() {
  let [params] = useSearchParams();
  const guestsParam = params.get('guests') ?? '';
  const guests = guestsParam.split(',');

  const tickets = parseInt(params.get('tickets') ?? '0');
  const [message, setMessage] = useState<string | null>(null);
  const [confirmed, setConfirmed] = useState<string[]>(guests);
  const [radioSelection, setRadioSelection] = useState(null);
  const [isAnswering, setIsAnswering] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const onAnswer = () => {
    setIsAnswering(true);
    setMessage(null);
  };
  const onAdd = () => {
    setConfirmed([...confirmed, '']);
    if (tickets > 1 && radioSelection) {
      setMessage(`Boletos tentativos, sujetos a disponibilidad: ${tickets}.`);
    }
  };
  const onChangeName = (value:string, index:number) => {
    let newConfirmed = [...confirmed];
    newConfirmed[index] = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z\s]/g, '');
    setConfirmed(newConfirmed);
  };
  const onDelete = (index:number) => {
    let newConfirmed = [...confirmed];
    newConfirmed.splice(index, 1);
    setConfirmed(newConfirmed);
    if (newConfirmed.length == 1) {
      if (tickets > 1 && radioSelection) {
        setMessage(`Boletos tentativos, sujetos a disponibilidad: ${tickets}.`);
      } else {
        setMessage(null);
      }
    }
  };
  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioSelection(e.target.value);
    if (e.target.value) {              
      if (tickets > 1) {
        setMessage(`Boletos tentativos, sujetos a disponibilidad: ${tickets}.`);
      } else {
        setMessage(null);
      }
    } else {
      setMessage(null);
    }
  };
  const checkConfirmed = () => {
    return !confirmed.some((value) => value.length === 0) && radioSelection != null;
  };
  const onSubmit = async () => {
    setIsSending(true);
    const trimedConfirmed = confirmed.map((guest) => guest.trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z\s]/g, ''));
    await saveGuests(radioSelection!, trimedConfirmed);
    setIsSending(false);
    setConfirmed(['']);
    setRadioSelection(null);
    setMessage('¡Se ha enviado tu confirmación!');
    setIsAnswering(false);
  };

  return (
    <div className="attendance-form-container">
      <h2>¿Asistirás a nuestra boda?</h2>
      <hr className="line" />
      <>
        {isAnswering ? (
          <>
            <Radio.Group className="radios" onChange={onChangeRadio} value={radioSelection}>
              <Radio value={true}>¡Sí, no me lo pierdo!</Radio>
              <Radio value={false}>No podré asistir, lo siento.</Radio>
            </Radio.Group>
            {radioSelection != null && (
              <>
                <span className="person-label">{`Personas que ${radioSelection ? '' : 'NO'} asistirán`}</span>
                <Input className="main" onChange={(e) => {
                  onChangeName(e.target.value, 0);
                } } value={confirmed[0]} placeholder="Nombre completo" />
              </>
            )}
            {confirmed[0].length > 0 && radioSelection != null && (
              <>
                {confirmed.map((value, index) => {
                  if (index > 0) {
                    return (
                      <div key={index} className="person-row">
                        <Input className="companion" onChange={(e) => {
                          onChangeName(e.target.value, index);
                        }} value={value} placeholder="Nombre completo" />
                        <Button className="delete-button" type="text" onClick={() => {
                            onDelete(index);
                          }}
                        ><DeleteOutlined /></Button>
                      </div>
                    );
                  }
                  return null;
                })}
                {confirmed.length < tickets && confirmed.length < 10 && radioSelection != null && (
                  <Button className="add-companion" onClick={onAdd}>{`+ Agregar ${radioSelection ? 'acompañante' : 'persona'}`}</Button>
                )}                
              </>
            )}
            {checkConfirmed() && (
              <Button className="submit-button" onClick={onSubmit} loading={isSending} >Enviar</Button>
            )}
          </>
        ) : (
          <Button className="answer-button" onClick={onAnswer}>Responder</Button>
        )}
      </>      
      {message && <span className="message">{message}</span>}      
    </div>
  );
}

export default Attendance;
