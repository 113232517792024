import React, { useState } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { HOME } from '../../constants/routes';
import { login } from '../../actions/auth.actions';

type FieldType = {
  username?: string;
  password?: string;
};

function Login() {
  const [message, setMessage] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (userData: FieldType) => {
    setMessage(null);
    setLoading(true);
    const user = await login(userData.username!, userData.password!);
    const permissions = user?.permissions;
    setLoading(false);
    if (user != null && permissions.length > 0) {
      navigate(HOME);
    } else {
      setMessage('Usuario o contraseña incorrecta.');
    }
  };

  const onFormChange = () => {
    setMessage(null);
  };
  return (
    <section className="login-section">
      <Card className="login-card">
        <h2 className="title">Acceso</h2>
        <Form className="login-form" name="login" onFinish={onSubmit}>
          <Form.Item<FieldType>
            name="username"
            rules={[{ 
              required: true,
              message: 'Introduce tu nombre de usuario',
            }]}
          >
            <Input 
              onChange={onFormChange}
              onFocus={onFormChange}
              prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="Usuario"
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="password"
            rules={[{ 
              required: true,
              message: 'Introduce tu contraseña',
            }]}
          >
            <Input
              onChange={onFormChange}
              onFocus={onFormChange}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Contraseña" 
            />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} className="login-button" type="primary" htmlType="submit">
              Ingresar
            </Button>
          </Form.Item>
          {message && <span>{message}</span>}        
        </Form>
      </Card>
    </section>
  );
}

export default Login;
