import React from 'react';
import './Contact.scss';

function Contact() {
  return (
    <section className="contact-section">
      <div className="container">
        <h2>Contacto de Ayuda</h2>
        <hr className="line" />
        <h3>Líder de Damas de Honor</h3>
        <h4>Gloria Luz Castillo</h4>
        <h4>55 48 83 26 81</h4>
      </div>
    </section>
  );
}

export default Contact;
