import React from 'react';
import './Parents.scss';

function Parents() {
  return (
    <section className="parents-section">
      <div className="main-container">
        <div className="parent-container">
          <h2>Nuestros Padrinos</h2>
          <h3>Guadalupe Hernández Maciel</h3>
          <h3>Jorge Del Valle Rincón</h3>
        </div>
        <div className="two-sides-container">
          <div className="parent-container">            
            <h2>Padres de la Novia</h2>
            <h3>Gloria Luz Barrera Gutiérrez</h3>
            <h3>Jesús Castillo Galindo</h3>
          </div>
          <div className="parent-container">            
            <h2>Padres del Novio</h2>
            <h3>Aide Ortiz Ruiz</h3>
            <h3>Arturo Hernández Maciel</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Parents;
