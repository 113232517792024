import React from 'react';
import './Farewell.scss';

function Farewell() {
  return (
    <section className="farewell-section">
      <div className="container">
        <div className="text-container">
          <h3>Nos hace mucha ilusión que nos acompañes en el viaje más importante de nuestras vidas.</h3>
          <h4>¡Te esperamos!</h4>
          <h2>Gaby y Oscar</h2>
        </div>
      </div>
    </section>
  );
}

export default Farewell;
