import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './Schedule.scss';
import Church from '../../../assets/img/church.svg';
import Sign from '../../../assets/img/sign.svg';
import Plate from '../../../assets/img/plate.svg';
import Toast from '../../../assets/img/Toast.svg';
import Dance from '../../../assets/img/dance.svg';
import Bell from '../../../assets/img/bell.svg';
import { TABLET_MAX_WIDTH } from '../../../constants/breakPoints';

function Schedule() {
  return (
    <section className="schedule-section">
      <div className="schedule-container">
        <h2>Programa</h2>
        <hr />
        <div className="events-container">
          <Event 
            image={Church}
            name="Ceremonia Religiosa" 
            description="Acompañanos a unir nuestras vidas con la bendición de Dios." 
            time="16:00 hrs" />
          <div className="divider" />
          <Event 
            image={Bell}
            name="Recepción" 
            description="Tómate las mejores fotos con nosotros, mientras disfrutamos de un rico cocktail" 
            time="18:00 hrs" />
          <div className="divider" />
          <Event 
            image={Toast}
            name="Brindis" 
            description="Brindemos juntos para dar gracias a dios, por este maravilloso momento."
            time={null} />
          <div className="divider" />
          <Event 
            image={Plate}
            name="Banquete" 
            description="Disfrutaremos de una deliciosa cena." 
            time={null} />
          <div className="divider" />
          <Event 
            image={Dance}
            name="Primer Baile" 
            description="Acompañanos al momento más emotivo de la fiesta." 
            time={null} />
        </div>
      </div>
    </section>
  );
}

interface Props {
  image:string,
  name:string,
  description:string,
  time:string | null,
}

function Event({image, name, description, time = null}:Props) {
  const isTablet = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });
  return (
    <div className="event-container">
      <div className="image-container">
        <img src={image} alt='' />
      </div>      
      <div className="information-container">
        <span className="title">{name}</span>
        <p>{description}</p>
        {time && isTablet && <span className="time">{time}</span>}
      </div>
      {time && !isTablet && <span className="time">{time}</span>}      
    </div>
  );
}

export default Schedule;
