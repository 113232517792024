import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { getItem } from '../../utils/storage';

function AuthRequired() {
  const location = useLocation();
  const user = getItem('user');
  let allowed = false;
  if (user == null) {
    return <Navigate to={routes.LOGIN} state={{ from: location }} />;
  }
  switch(location.pathname) {
    case routes.ACCESS: {
      allowed = user.permissions.includes(2);
      break;
    }
    default: {
      allowed = true;
      break;
    }
  }
  return allowed ? (
    <Outlet />
  ) : (
    <Navigate to={routes.HOME} state={{ from: location }} />
  );
}

export default AuthRequired;
