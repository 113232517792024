import React from 'react';
import './GiftTable.scss';
import Amazon from '../../../assets/img/Amazon_logo.webp';
import BBVA from '../../../assets/img/BBVA_logo.webp';
import { Card } from 'antd';

function GiftTable() {
  return (
    <section className="gift-table-section">
      <div className="main-container">
        <h2>Mesa de Regalos</h2>
        <hr className="line" />
        <h3>Tu presencia es nuestro mejor regalo, pero si quieres tener un detalle con nosotros, te damos algunas ideas</h3>
        <div className="two-sides-container">
          <div className="side-container">
            <span>Si deseas obsequiarnos algo, te compartimos algunas opciones.</span>
            <a target="_blank" href="https://www.amazon.com.mx/wedding/luz-gabriela-castillo-barrera-oscar-hernandez-ortiz-cdmx-november-2023/registry/I1WSL9BYVSZL">
              <Card className="amazon-card">
                <img className="amazon-logo" src={Amazon} alt="amazon" />
              </Card>              
            </a>
          </div>
          <div className="side-container">
            <span>También puedes hacer que nuestra luna de miel sea inolvidable.</span>
            <Card className="bbva-card">
              <img className="bbva-logo" src={BBVA} alt="bbva" />
              <div className="data-container">
                <h4>Luz Gabriela Castillo Barrera</h4>
                <h4>Cuenta: 1541012088</h4>
                <h4>Clabe: 012180015410120884</h4>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GiftTable;
