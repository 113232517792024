import React from 'react';
import './Place.scss';
import { useMediaQuery } from 'react-responsive';
import { PHONE_MAX_WIDTH, SMALL_SCREEN_WIDTH, TABLET_MAX_WIDTH } from '../../../constants/breakPoints';

interface Props {
  type:string; 
  name:string;
  date:string; 
  time:string; 
  address:string; 
  link:string;
  footnote:string;
  photo: string;
  classname?: string;
}

function Place({ classname = '', type, name, date, time, address, link, footnote, photo }:Props) {
  const isSmall = useMediaQuery({ maxWidth: SMALL_SCREEN_WIDTH });
  const isTablet = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });
  const isPhone = useMediaQuery({ maxWidth: PHONE_MAX_WIDTH });
  const renderDeviceType = () => {
    if (isPhone) {
      return 'Phone';
    }
    if (isTablet) {
      return 'Tablet';
    }
    if (isSmall) {
      return 'Small';
    }
    return 'Big';
  };
  return (
    <section className={`place-section ${classname}`}>
      {/* <span className="device-type" >{renderDeviceType()}</span> */}
      <div className="main-container">
        <div className="place-container">
          <div className="centered">
            <div className="title-container">
              <h2>{type}</h2>
            </div>
            <hr className="line" />
            <h3>{name}</h3>            
            {isTablet && (
              <div className="photo">
                <img className="bbva-logo" src={photo} alt="bbva" />
              </div>
            )}
          </div>
          <p>{date}</p>
          <p className="time">{time}</p>
          <p>{address}</p>
          <a href={link} target="_blank">Ver ubicación</a>
          <span>{footnote}</span>
        </div>
        {!isTablet && (
          <div className="photo">
            <img className="bbva-logo" src={photo} alt="bbva" />
          </div>
        )}
      </div>
    </section>
  );
}

export default Place;
