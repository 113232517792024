import api from './api';
import { getApiURL } from './servers';
import * as endpoints from '../constants/servicesRoutes'; 

export const saveGuests = (data: any) =>
  api.post(`${getApiURL()}${endpoints.SAVE_GUESTS}`, data);
  
export const getGuests = (data: any) =>
  api.post(`${getApiURL()}${endpoints.GET_GUESTS}`, data);
  
export const getTables = (data: any) =>
  api.post(`${getApiURL()}${endpoints.GET_TABLES}`, data);

export const getGroups = (data: any) =>
  api.post(`${getApiURL()}${endpoints.GET_GROUPS}`, data);
  
export const updateGuest = (data: any) =>
  api.post(`${getApiURL()}${endpoints.UPDATE_GUEST}`, data);

export const deleteGuest = (data: any) =>
  api.post(`${getApiURL()}${endpoints.DELETE_GUEST}`, data);

export const changeGuestRegister = (data: any) =>
  api.post(`${getApiURL()}${endpoints.CHANGE_GUEST_REGISTER}`, data);
  