import { GuestDataType, OptionType } from '../DataTypes';
import * as guestService from '../services/guests.service';

interface RegisterType {
  id: number,
  name: string,
  guest_group: number,
  table_number: number,
  used: number,
  attend: number,
}

export const saveGuests = async (attend: boolean, names: string []) => {
  const request = {
    attend: attend  ? 1 : 0,
    names,
  };
  try {
    await guestService.saveGuests(request);
  } catch (error) {
    console.error(error);
  }
};

export const getGuests = async (
    name: string,
    group: number,
    table: number,
    used: number,
    attend: number,
    id: number|null = null,
  ) => {
  const request = {
    id,
    name,
    guestGroup: group == -1 ? null : group,
    table: table == 0 ? null : table,
    used: used == -1 ? null : used,
    attend: attend == -1 ? null : attend,
  };
  try {
    const response = await guestService.getGuests(request);
    const data: RegisterType[] = response.data.data;
    const guestsData: GuestDataType[] =  data.map(({ id, name, guest_group, table_number, attend, used }, index) => {
      let willAttend: boolean | undefined;
      let isUsed: boolean | undefined;
      if (attend == -1) {
        willAttend = undefined;
      } else {
        willAttend = attend == 1;
      }
      if (used == -1) {
        isUsed = undefined;
      } else {
        isUsed = used == 1;
      }
      return (
        {
          localIndex: index + 1,
          key: id,
          id,
          name,
          guestGroup: guest_group,
          table: table_number == -1 ? undefined : table_number,
          attend: willAttend, 
          used: isUsed,
        }
      );
    });
    return guestsData;
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const getTables = async () => {
  try {
    const response = await guestService.getTables({});
    const data = response.data.data;
    const tablesOptions: OptionType[] =  data.map((table:number) => ({
      label: table.toString(),
      value: table,
    }));
    return tablesOptions;
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const getGroups = async () => {
  try {
    const response = await guestService.getGroups({});
    const data = response.data.data;
    const tablesOptions: OptionType[] =  data.map((group:number) => ({
      label: group.toString(),
      value: group,
    }));
    return tablesOptions;
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const updateGuest = async (id: number, attribute: any) => {
  const request = {
    ...{      
      id,
      name: null,
      group: null,
      table: null,
      used: null,
      attend: null
    },
    ...attribute,
  };
  try {
    const response = await guestService.updateGuest(request);
    return response.data.success;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const deleteGuest = async (id: number) => {
  const request = {   
    id,
  };
  try {
    const response = await guestService.deleteGuest(request);
    return response.data.success;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const changeGuestRegister = async (id: number, used: number) => {
  const request = {      
    id,
    used,
  };
  try {
    const response = await guestService.changeGuestRegister(request);
    return { success: response.data.success, message: response.data.message };
  } catch (error) {
    console.error(error);
  }
  return { success: false, message: 'Error en el registro' };
};
