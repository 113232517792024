import React from 'react';
import './Story.scss';
import { Carousel } from 'antd';

function Story() {
  return (
    <section className="story-section">
      <div className="main-container">
        <h2>Nuestra Historia</h2>
        <hr className="line" />
        <h3>“Cuando amas a alguien, lo amas como es, y no como quisieras que sea” – León Tolstói.</h3>
        <Carousel className="photos" autoplay>
          <div className="photo p15" />
          <div className="photo p1" />
          <div className="photo p3" />
          <div className="photo p4" />
          <div className="photo p5" />
          <div className="photo p6" />
          <div className="photo p7" />
          <div className="photo p8" />
          <div className="photo p9" />
          <div className="photo p10" />
          <div className="photo p12" />
          <div className="photo p13" />
          <div className="photo p14" />
        </Carousel>
      </div>
    </section>
  );
}

export default Story;
