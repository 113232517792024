import React from 'react';
import Facebook from '../../../assets/img/facebook.svg';
import Instagram from '../../../assets/img/instagram.svg';
import './Recalls.scss';

function Recalls() {
  return (
    <section className="recalls-section">
      <div className="main-container">
        <h2>Nuestros Recuerdos</h2>
        <hr className="line" />
        <h3>#Hashtag</h3>
        <p>No olvides etiquetarnos en todas tus publicaciones de Instagram y Facebook utilizando nuestro Hashtag…</p>
        <span>#BodaG&O</span>
        <div className="social-container">
          <img className="logo" src={Instagram} alt='' />
          <img className="logo" src={Facebook} alt='' />
        </div>
      </div>
    </section>
  );
}

export default Recalls;
