import React, { useEffect, useState } from 'react';
import Countdown from './Countdown/Countdown';
import SaveTheDate from './SaveTheDate/SaveTheDate';
import Place from './Place/Place';
import Schedule from './Schedule/Schedule';
import DressCode from './DressCode/DressCode';
import Story from './Story/Story';
import Parents from './Parents/Parents';
import GiftTable from './GiftTable/GiftTable';
import Recalls from './Recalls/Recalls';
import Attendance from './Attendance/Attendance';
import Contact from './Contact/Contact';
import Farewell from './Farewell/Farewell';
import WelcomeModal from '../../Components/WelcomeModal/WelcomeModal';
import SanJuanBautista from '../../assets/img/san_juan_bautista.webp';
import Lebraff from '../../assets/img/lebraff2.webp';
import './Main.scss';
import Sound from '../../Components/Sound/Sound';
import useSound from 'use-sound';
import FullLoader from '../../Components/FullLoader/FullLoader';

const mySound = require('../../assets/music/photograph.mp3');

function Main() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [playSound, { stop }] = useSound(mySound, { volume: 0.7 });

  const onUserStart = () => {
    setIsWelcomeVisible(false);
    setIsPlaying(true);
    playSound();
  };
  
  const onPlayClick = () => {
    if (isPlaying) {
      stop();
    } else {
      playSound();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // callback function to call when event triggers
    let timer: ReturnType<typeof setTimeout>;
    const onPageLoad = () => {
      console.log('page loaded');
      timer = setTimeout(() => {
        setLoading(false);
        setIsWelcomeVisible(true);
      }, 2000);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => {
        window.removeEventListener('load', onPageLoad);
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <>
      <FullLoader visible={loading} />  
      <Countdown />
      <SaveTheDate />
      <Place 
        type="Ceremonia religiosa"
        name="Parroquia San Juan Bautista"
        date="Sábado 4 de noviembre del 2023"
        time="16:00 hrs"
        address="Parque Centenario 8, Coyoacán, 04000 Ciudad de México, CDMX"
        link={'https://goo.gl/maps/7QS2QtWqJeLcpEgm6'}
        footnote="Estacionamiento sugerido: https://goo.gl/maps/vtjQWTtNKekr8LZK8" 
        photo={SanJuanBautista}
      />
      <Place 
        classname="lebraff-place"
        type="Recepción"
        name="Salón LEBRAFF"
        date="Sábado 4 de noviembre del 2023"
        time="18:00 hrs"
        address="Av. Aztecas 432, Ajusco, Coyoacán, 04300 Ciudad de México, CDMX"
        link={'https://goo.gl/maps/hiSnE2uTwP8DUoUv9'}
        footnote="Valet parking, máximo 30 lugares, costo $50"  
        photo={Lebraff}     
      />
      <Schedule />
      <DressCode />
      <Story />
      <Parents />
      <GiftTable />
      <Recalls />
      <Attendance />
      <Contact />
      <Farewell />
      <WelcomeModal visible={isWelcomeVisible} onDismiss={onUserStart} />
      <Sound isPlaying={isPlaying} onPlayClick={onPlayClick} />
    </>
  );
}

export default Main;
