import React from 'react';
import './SaveTheDate.scss';

function SaveTheDate() {
  return (
    <section className="save-the-date-section">
      <div className="container">
        <h4>Porque los momentos más importantes merecen ser compartidos.</h4>
        <div className="save-date-wrapper">
          <h3>SAVE THE DATE</h3>
          <h2>04 - 11 - 2023</h2>
        </div>
        <h4>Nos hará muy felices que nos acompañes en este día tan especial.</h4>
      </div>
    </section>
  );
}

export default SaveTheDate;
